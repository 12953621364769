/* eslint-disable react/jsx-pascal-case */
import { useCallback, useEffect, useMemo, useRef, useState } from "react";
import {
  ColumnFiltersState,
  Getter,
  PaginationState,
  SortingState,
} from "@tanstack/react-table";
import { DateTime } from "luxon";
import {
  MRT_ColumnDef,
  MRT_ToggleFullScreenButton,
  MRT_ShowHideColumnsButton,
  MRT_ToggleDensePaddingButton,
  MRT_ToggleFiltersButton,
  MRT_ToggleGlobalFilterButton,
  MaterialReactTable,
} from "material-react-table";
import { useSnackbar } from "notistack";
import { Box, Button, IconButton, Tooltip } from "@mui/material";
import { Add, Delete, Edit, Refresh, Visibility } from "@mui/icons-material";

import useFetchEntitiesPerPage from "../../hooks/useFetchEntitiesPerPage";
import filterModel from "../../hooks/filterModel";

import StatusButton from "../../component/statusButton";
import CustomDateFilter from "../../component/customDateFilter";
import CustomFilterPanel from "../../component/customFilterPanel";

import DeleteRole from "./deleteRole";
import CreateRoles from "./createRole";
import UpdateRoles from "./updateRole";
import ViewRoleDetails from "./roleDetails";

import useUpdateEntity from "../../hooks/useUpdateEntity";
import useAccessControl from "../../hooks/useAccessControl";

// Roles List View Component
function Roles() {
  const { rolesList, updateRole, createRoles, roleDetails, deleteRole } =
    useAccessControl();

  // USESTATES & USEREFS
  const [columnFilters, setColumnFilters] =
    useState<ColumnFiltersState>() as any;
  const [columnFilterFns, setColumnFilterFns] = useState({
    created_at: "is",
    name: "contains",
    updated_at: "is",
    status: "is",
  }) as any;
  const [globalFilter, setGlobalFilter] = useState<any>();
  const [sorting, setSorting] = useState<SortingState>() as any;
  const [pagination, setPagination] = useState<PaginationState>({
    pageIndex: 0,
    pageSize: 15,
  });
  const [open, setOpen] = useState<boolean>(false);
  const [update, setUpdate] = useState<boolean>(false);
  const [view, setView] = useState<boolean>(false);
  const [openDeleteDialog, setOpenDeleteDialog] = useState<boolean>(false);

  const roleData = useRef({}) as any;

  const { enqueueSnackbar } = useSnackbar();
  const sort = useMemo(
    () =>
      sorting?.map(
        (item: {
          id?: string;
          field?: string;
          sort?: string;
          desc?: string;
        }) => ({
          field: item?.id || item?.field,
          sort: item?.sort === "desc" || item?.desc ? "desc" : "asc",
        })
      ),
    [sorting]
  );

  const {
    data: roles,
    isLoading,
    refetch,
  } = useFetchEntitiesPerPage(
    {
      endPoint: "roles",
      page: pagination.pageIndex,
      perPage: pagination.pageSize,
      filterModel: filterModel(columnFilterFns, columnFilters) as any,
      sortModel: sort,
      searchText: globalFilter,
    },
    { enabled: rolesList }
  ) as any;
  const {
    mutate,
    data: statusUpdate,
    isPending,
  } = useUpdateEntity(`roles/${roleData.current.id}`);

  // EVENT HANDLERS & HELPERS
  const handleClickOpen = (id: string) => {
    setOpenDeleteDialog(true);
    roleData.current.id = id;
  };
  const handleUpdate = (role: any) => {
    roleData.current = role;
    setUpdate(true);
    return roleData.current;
  };
  const handelView = (role: any) => {
    roleData.current = role;
    setView(true);
    return roleData.current;
  };
  const handleSwitch = useCallback(
    (role: any) => {
      roleData.current = role;
      mutate({ status: role.status === "ACTIVE" ? "INACTIVE" : "ACTIVE" });
    },
    [mutate]
  );
  const handleModalClose = () => {
    setOpen(false);
  };
  const handleEditModalClose = () => {
    setUpdate(false);
  };
  const handleViewModalClose = () => {
    setView(false);
  };
  const datePicker = (props: any) => <CustomDateFilter {...props} />;

  const statusButton = useCallback(
    ({
      cell,
    }: {
      cell: { getValue: Getter<string>; row: { original: any } };
    }) => (
      <StatusButton
        loading={isPending}
        disabled={!updateRole}
        status={cell.getValue()}
        onChange={() => handleSwitch(cell?.row?.original)}
      />
    ),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [isPending, updateRole]
  );
  const actions = useCallback(
    ({ row }: { row: { original: any } }) => (
      <Box>
        {updateRole && (
          <Tooltip title="Update role">
            <IconButton
              sx={{ color: "primary.main" }}
              onClick={() => handleUpdate(row?.original)}
            >
              <Edit />
            </IconButton>
          </Tooltip>
        )}
        {roleDetails && (
          <Tooltip title="View role">
            <IconButton
              sx={{ color: "primary.main" }}
              onClick={() => handelView(row?.original)}
            >
              <Visibility />
            </IconButton>
          </Tooltip>
        )}
        {deleteRole && (
          <Tooltip title="Delete role">
            <IconButton
              color="error"
              onClick={() => handleClickOpen(row.original.id)}
            >
              <Delete />
            </IconButton>
          </Tooltip>
        )}
      </Box>
    ),
    [deleteRole, roleDetails, updateRole]
  );

  // USEFFECTS
  useEffect(() => {
    if (statusUpdate) {
      enqueueSnackbar(`Succesfully changed status.`, {
        variant: "success",
        preventDuplicate: true,
        autoHideDuration: 2000,
      });
      refetch();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [statusUpdate]);

  // DATA STRUCTURE
  const columns = useMemo<MRT_ColumnDef<object>[]>(
    () => [
      {
        size: 220,
        accessorKey: "created_at",
        header: "Created At",
        filterVariant: "date" as any,
        renderColumnFilterModeMenuItems: CustomFilterPanel,
        Cell: ({ cell }: { cell: { getValue: Getter<string> } }) =>
          DateTime.fromISO(cell?.getValue()).toLocaleString(
            DateTime.DATETIME_MED
          ),
        Filter: datePicker,
      },
      {
        accessorKey: "name",
        header: "Name",
        renderColumnFilterModeMenuItems: CustomFilterPanel,
      },
      {
        size: 220,
        accessorKey: "status",
        header: "Status",
        filterVariant: "select",
        filterSelectOptions: ["ACTIVE", "INACTIVE"],
        Cell: statusButton,
        renderColumnFilterModeMenuItems: CustomFilterPanel,
      },
      {
        size: 220,
        accessorKey: "updated_at",
        header: "Last Updated",
        filterVariant: "date" as any,
        renderColumnFilterModeMenuItems: CustomFilterPanel,
        Cell: ({ cell }: { cell: { getValue: Getter<string> } }) =>
          DateTime.fromISO(cell?.getValue()).toLocaleString(
            DateTime.DATETIME_MED
          ),
        Filter: datePicker,
      },
      {
        accessorKey: "actions",
        header: "Actions",
        enableSorting: false,
        flex: 1,
        enableColumnFilter: false,
        Cell: actions,
      },
    ],
    [actions, statusButton]
  );

  // RENDERING
  return (
    <Box sx={{ height: "100%" }}>
      {open && (
        <CreateRoles
          handleModalClose={handleModalClose}
          refetch={refetch}
          open={open}
        />
      )}
      {update && (
        <UpdateRoles
          handleUpdateModalClose={handleEditModalClose}
          roleData={roleData.current}
          refetch={refetch}
          update={update}
        />
      )}
      {view && (
        <ViewRoleDetails
          handleViewModalClose={handleViewModalClose}
          roleData={roleData.current}
          view={view}
        />
      )}
      {openDeleteDialog && (
        <DeleteRole
          id={roleData.current.id}
          open={openDeleteDialog}
          setOpen={setOpenDeleteDialog}
          refetch={() => refetch()}
        />
      )}
      <Box sx={{ flex: 1, height: "100%" }}>
        <MaterialReactTable
          data={roles ? roles.data : []}
          columns={columns}
          initialState={{
            columnPinning: {
              left: ["mrt-row-select"],
              right: ["actions"],
            },
          }}
          enableColumnResizing
          enableColumnFilterModes
          enableStickyHeader
          enableColumnOrdering
          enableRowSelection
          enablePinning
          manualFiltering
          manualPagination
          manualSorting
          filterFns={{
            after: (row: any, filterValue) => row.customField === filterValue,
          }}
          muiFilterTextFieldProps={({ column }: any) => ({
            helperText: `Filter Mode: ${columnFilterFns[column?.id]}`,
          })}
          onColumnFiltersChange={setColumnFilters}
          onColumnFilterFnsChange={setColumnFilterFns}
          onGlobalFilterChange={setGlobalFilter}
          onPaginationChange={setPagination}
          onSortingChange={setSorting}
          renderTopToolbarCustomActions={() => (
            <Box
              sx={{
                display: "flex",
                justifyContent: "flex-end",
              }}
            >
              {createRoles && (
                <Button
                  size="small"
                  sx={{
                    bgcolor: "primary.main",
                    color: "secondary.main",
                    textTransform: "none",
                    px: 2,
                    py: 1,
                    mx: 1,
                    boxShadow: 2,
                    " :hover": {
                      bgcolor: "primary.main",
                      color: "secondary.main",
                    },
                  }}
                  onClick={() => setOpen(true)}
                >
                  <Add sx={{ mr: 1 }} />
                  Add Role
                </Button>
              )}
            </Box>
          )}
          renderToolbarInternalActions={({ table }: any) => (
            <>
              <Tooltip arrow title="Refresh Data">
                <IconButton onClick={() => refetch()}>
                  <Refresh />
                </IconButton>
              </Tooltip>
              <MRT_ToggleGlobalFilterButton table={table} />
              <MRT_ToggleFiltersButton table={table} />
              <MRT_ShowHideColumnsButton table={table} />
              <MRT_ToggleDensePaddingButton table={table} />
              <MRT_ToggleFullScreenButton table={table} />
            </>
          )}
          muiTableContainerProps={{
            sx: { maxHeight: `calc(100vh - 225px)` },
          }}
          muiTableHeadCellProps={{
            sx: {
              "& .Mui-TableHeadCell-Content": {
                justifyContent: "space-between",
              },
            },
          }}
          muiTableBodyCellProps={({ table, column }) => {
            return {
              sx: {
                "&.MuiTableCell-root": {
                  boxShadow:
                    table.getState().columnPinning?.right?.[0] === column?.id
                      ? "-7px 0px 10px -1.7px lightgray"
                      : table
                            .getState()
                            .columnPinning?.left?.some((el) => el === column.id)
                        ? "7px 0px 10px -1.7px lightgray"
                        : "none",
                },
              },
            };
          }}
          rowCount={roles?.meta_data?.total ?? 0}
          state={{
            columnFilters: columnFilters || [],
            columnFilterFns,
            globalFilter: globalFilter || "",
            isLoading,
            pagination,
            sorting: sorting || [],
            showSkeletons: isLoading,
          }}
        />
      </Box>
    </Box>
  );
}
export default Roles;
