/* eslint-disable react/jsx-pascal-case */
import { useMemo, useCallback, useState } from "react";
import { Box, IconButton, Tooltip } from "@mui/material";
import {
  MaterialReactTable,
  MRT_ColumnDef,
  MRT_ToggleFullScreenButton,
  MRT_ShowHideColumnsButton,
  MRT_ToggleDensePaddingButton,
  MRT_ToggleFiltersButton,
  MRT_ToggleGlobalFilterButton,
} from "material-react-table";
import { DateTime } from "luxon";
import { Refresh } from "@mui/icons-material";
import {
  ColumnFiltersState,
  Getter,
  PaginationState,
  SortingState,
} from "@tanstack/react-table";

import filterModel from "../../hooks/filterModel";
import StatusButton from "../../component/statusButton";
import useAccessControl from "../../hooks/useAccessControl";
import CustomDateFilter from "../../component/customDateFilter";
import CustomFilterPanel from "../../component/customFilterPanel";
import useFetchEntitiesPerPage from "../../hooks/useFetchEntitiesPerPage";

function Customers() {
  const { customersList } = useAccessControl();
  const [columnFilters, setColumnFilters] =
    useState<ColumnFiltersState>() as any;
  const [columnFilterFns, setColumnFilterFns] = useState({
    created_at: "is",
    full_name: "contains",
    phone_number: "contains",
    email: "contains",
    status: "is",
    updated_at: "is",
  }) as any;
  const [globalFilter, setGlobalFilter] = useState<any>();
  const [sorting, setSorting] = useState<SortingState>() as any;
  const [pagination, setPagination] = useState<PaginationState>({
    pageIndex: 0,
    pageSize: 15,
  });

  const sort = useMemo(
    () =>
      sorting?.map(
        (item: {
          id?: string;
          field?: string;
          sort?: string;
          desc?: string;
        }) => ({
          field: item?.id || item?.field,
          sort: item?.sort === "desc" || item?.desc ? "desc" : "asc",
        })
      ),
    [sorting]
  );

  const {
    data: customers,
    isLoading,
    refetch,
  } = useFetchEntitiesPerPage(
    {
      endPoint: "customers",
      page: pagination.pageIndex,
      perPage: pagination.pageSize,
      filterModel: filterModel(columnFilterFns, columnFilters) as any,
      sortModel: sort,
      searchText: globalFilter,
    },
    {
      enabled: customersList,
    }
  ) as any;

  // Event Handlers & Helpers
  const statusButton = useCallback(
    ({ row }: { row: { original: any } }) => (
      <StatusButton status={row.original.status} />
    ),
    []
  );

  const dateFilter = useCallback(
    (dateProps: any) => <CustomDateFilter {...dateProps} />,
    []
  );

  // DATA STRUCTURE
  const columns = useMemo<MRT_ColumnDef<object>[]>(
    () => [
      {
        size: 300,
        accessorKey: "created_at",
        header: "Registered Time",
        filterVariant: "date" as any,
        align: "center",
        enableSorting: true,
        enableColumnFilter: true,
        enableGlobalFilter: true,
        renderColumnFilterModeMenuItems: CustomFilterPanel,
        Cell: ({ cell }: { cell: { getValue: Getter<string> } }) =>
          DateTime.fromISO(cell?.getValue()).toLocaleString(
            DateTime.DATETIME_MED
          ),
        Filter: dateFilter as any,
      },
      {
        size: 240,
        accessorKey: "full_name",
        header: "Name",
        align: "center",
        enableSorting: true,
        enableColumnFilter: true,
        enableGlobalFilter: true,
        renderColumnFilterModeMenuItems: CustomFilterPanel,
      },
      {
        size: 240,
        accessorKey: "phone_number",
        header: "Phone",
        align: "center",
        enableSorting: true,
        enableColumnFilter: true,
        enableGlobalFilter: true,
        renderColumnFilterModeMenuItems: CustomFilterPanel,
      },
      {
        size: 240,
        accessorKey: "email",
        header: "Email",
        align: "center",
        enableSorting: true,
        enableColumnFilter: true,
        enableGlobalFilter: true,
        renderColumnFilterModeMenuItems: CustomFilterPanel,
      },
      {
        size: 220,
        accessorKey: "status",
        header: "Status",
        filterVariant: "select" as any,
        filterSelectOptions: ["ACTIVE", "INACTIVE", "PENDING"],
        Cell: statusButton,
        renderColumnFilterModeMenuItems: CustomFilterPanel,
        enableSorting: true,
        enableColumnFilter: true,
        enableGlobalFilter: true,
        align: "center",
      },
      {
        size: 300,
        accessorKey: "updated_at",
        header: "Last Updated",
        filterVariant: "date" as any,
        align: "center",
        enableSorting: true,
        enableColumnFilter: true,
        enableGlobalFilter: true,
        renderColumnFilterModeMenuItems: CustomFilterPanel,
        Cell: ({ cell }: { cell: { getValue: Getter<string> } }) =>
          DateTime.fromISO(cell?.getValue()).toLocaleString(
            DateTime.DATETIME_MED
          ),
        Filter: dateFilter as any,
      },
    ],
    [dateFilter, statusButton]
  );
  return (
    <Box sx={{ height: "100%" }}>
      <Box sx={{ flex: 1, height: "100%" }}>
        <MaterialReactTable
          data={customers ? customers.data : []}
          columns={columns}
          initialState={{
            columnPinning: {
              left: ["mrt-row-select"],
              right: ["actions"],
            },
          }}
          enableColumnResizing
          enableColumnFilterModes
          enableStickyHeader
          enableColumnOrdering
          enableRowSelection
          enablePinning
          manualFiltering
          manualPagination
          manualSorting
          filterFns={{
            after: (row: any, filterValue) => row.customField === filterValue,
          }}
          muiFilterTextFieldProps={({ column }) => ({
            helperText: `Filter Mode: ${columnFilterFns[column?.id]}`,
          })}
          onColumnFiltersChange={setColumnFilters}
          onColumnFilterFnsChange={setColumnFilterFns}
          onGlobalFilterChange={setGlobalFilter}
          onPaginationChange={setPagination}
          onSortingChange={setSorting}
          renderToolbarInternalActions={({ table }) => (
            <>
              <Tooltip arrow title="Refresh Data">
                <IconButton onClick={() => refetch()}>
                  <Refresh />
                </IconButton>
              </Tooltip>
              <MRT_ToggleGlobalFilterButton table={table} />
              <MRT_ToggleFiltersButton table={table} />
              <MRT_ShowHideColumnsButton table={table} />
              <MRT_ToggleDensePaddingButton table={table} />
              <MRT_ToggleFullScreenButton table={table} />
            </>
          )}
          muiTableContainerProps={{
            sx: { maxHeight: `calc(100vh - 225px)` },
          }}
          muiTableHeadCellProps={{
            sx: {
              "& .Mui-TableHeadCell-Content": {
                justifyContent: "space-between",
              },
            },
          }}
          muiTableBodyCellProps={({ table, column }) => {
            return {
              sx: {
                "&.MuiTableCell-root": {
                  boxShadow:
                    table.getState().columnPinning?.right?.[0] === column?.id
                      ? "-7px 0px 10px -1.7px lightgray"
                      : table
                            .getState()
                            .columnPinning?.left?.some((el) => el === column.id)
                        ? "7px 0px 10px -1.7px lightgray"
                        : "none",
                },
              },
            };
          }}
          rowCount={customers?.meta_data?.total ?? 0}
          state={{
            columnFilters: columnFilters || [],
            columnFilterFns,
            globalFilter: globalFilter || "",
            isLoading,
            pagination,
            sorting: sorting || [],
            showSkeletons: isLoading,
          }}
        />
      </Box>
    </Box>
  );
}

export default Customers;
