/* eslint-disable react/jsx-pascal-case */
import { useCallback, useMemo, useRef, useState } from "react";
import { Box, IconButton, Tooltip } from "@mui/material";
import { DateTime } from "luxon";
import {
  MRT_ColumnDef,
  MRT_ToggleFullScreenButton,
  MRT_ShowHideColumnsButton,
  MRT_ToggleDensePaddingButton,
  MRT_ToggleFiltersButton,
  MRT_ToggleGlobalFilterButton,
  MaterialReactTable,
} from "material-react-table";
import {
  ColumnFiltersState,
  Getter,
  PaginationState,
  SortingState,
} from "@tanstack/react-table";

import filterModel from "../../hooks/filterModel";
import useUpdateEntity from "../../hooks/useUpdateEntity";
import useFetchEntitiesPerPage from "../../hooks/useFetchEntitiesPerPage";

import StatusButton from "../../component/statusButton";
import useAccessControl from "../../hooks/useAccessControl";
import CustomDateFilter from "../../component/customDateFilter";
import CustomFilterPanel from "../../component/customFilterPanel";
import { Refresh } from "@mui/icons-material";

// System Configurations List View Component
function Configs() {
  const { configsList } = useAccessControl();
  // USESTATES & USEREFS
  const [columnFilters, setColumnFilters] =
    useState<ColumnFiltersState>() as any;
  const [columnFilterFns, setColumnFilterFns] = useState({
    tax: "=",
    vat: "=",
    version: "=",
    status: "is",
    max_payout: "=",
    min_payout: "=",
    created_at: "is",
    updated_at: "is",
    service_fee: "=",
  }) as any;
  const [globalFilter, setGlobalFilter] = useState<any>();
  const [sorting, setSorting] = useState<SortingState>() as any;
  const [pagination, setPagination] = useState<PaginationState>({
    pageIndex: 0,
    pageSize: 15,
  });
  const configId = useRef<string>("");

  // QUERY & MUTATION
  const sort = useMemo(
    () =>
      sorting?.map(
        (item: {
          id?: string;
          field?: string;
          sort?: string;
          desc?: string;
        }) => ({
          field: item?.id || item?.field,
          sort: item?.sort === "desc" || item?.desc ? "desc" : "asc",
        })
      ),
    [sorting]
  );
  const {
    data: configs,
    isLoading,
    refetch,
  } = useFetchEntitiesPerPage(
    {
      endPoint: "configs",
      page: pagination.pageIndex,
      perPage: pagination.pageSize,
      filterModel: filterModel(columnFilterFns, columnFilters) as any,
      sortModel: sort,
      searchText: globalFilter,
    },
    {
      enabled: configsList,
    }
  ) as any;
  const { mutate, isPending } = useUpdateEntity(`configs/${configId.current}`);

  // EVENT HANDLERS & HELPERS
  const handleSwitch = useCallback(
    (config: { status: string; id: string }) => {
      configId.current = config?.id;
      mutate(
        {
          status: config.status === "ACTIVE" ? "INACTIVE" : "ACTIVE",
        },
        {
          onSuccess: () => refetch(),
        }
      );
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  );
  const statusButton = useCallback(
    ({ row }: { row: { original: any } }) => (
      <StatusButton
        loading={isPending}
        status={row.original?.status}
        onChange={() => handleSwitch(row?.original)}
      />
    ),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [isPending]
  );
  const datePicker = (props: any) => <CustomDateFilter {...props} />;

  // DATA STRUCTURE
  const columns = useMemo<MRT_ColumnDef<object>[]>(
    () => [
      {
        size: 190,
        accessorKey: "created_at",
        header: "Registered Time",
        filterVariant: "date" as any,
        renderColumnFilterModeMenuItems: CustomFilterPanel,
        Cell: ({ cell }: { cell: { getValue: Getter<string> } }) =>
          DateTime.fromISO(cell?.getValue()).toLocaleString(
            DateTime.DATETIME_MED
          ),
        Filter: datePicker,
      },
      {
        size: 150,
        accessorKey: "min_payout",
        header: "Min Payout",
        filterVariant: "number" as any,
        renderColumnFilterModeMenuItems: CustomFilterPanel,
      },
      {
        size: 160,
        accessorKey: "max_payout",
        header: "Max Payout",
        filterVariant: "number" as any,
        renderColumnFilterModeMenuItems: CustomFilterPanel,
      },
      {
        size: 160,
        accessorKey: "service_fee",
        header: "Service Fee",
        filterVariant: "number" as any,
        renderColumnFilterModeMenuItems: CustomFilterPanel,
      },
      {
        size: 100,
        accessorKey: "vat",
        header: "VAT",
        filterVariant: "number" as any,
        renderColumnFilterModeMenuItems: CustomFilterPanel,
      },
      {
        size: 100,
        accessorKey: "tax",
        header: "Tax",
        filterVariant: "number" as any,
        renderColumnFilterModeMenuItems: CustomFilterPanel,
      },
      {
        size: 140,
        accessorKey: "version",
        header: "Version",
        enableSorting: false,
        enableColumnFilter: false,
        enableGlobalFilter: false,
        filterVariant: "number" as any,
        renderColumnFilterModeMenuItems: CustomFilterPanel,
      },
      {
        size: 230,
        align: "center",
        accessorKey: "status",
        header: "Status",
        filterVariant: "select" as any,
        filterSelectOptions: ["ACTIVE", "INACTIVE", "PENDING"],
        Cell: statusButton as any,
        renderColumnFilterModeMenuItems: CustomFilterPanel,
      },
      {
        size: 190,
        accessorKey: "updated_at",
        header: "Updated At",
        filterVariant: "date" as any,
        renderColumnFilterModeMenuItems: CustomFilterPanel,
        Cell: ({ cell }: { cell: { getValue: Getter<string> } }) =>
          DateTime.fromISO(cell?.getValue()).toLocaleString(
            DateTime.DATETIME_MED
          ),
        Filter: datePicker,
      },
    ],
    [statusButton]
  );

  return (
    <Box sx={{ height: "100%" }}>
      <Box sx={{ flex: 1, height: "100%" }}>
        <MaterialReactTable
          data={configs ? configs.data : []}
          columns={columns}
          initialState={{
            columnPinning: {
              left: ["mrt-row-select"],
              right: ["actions"],
            },
          }}
          enableColumnResizing
          enableColumnFilterModes
          enableStickyHeader
          enableColumnOrdering
          enableRowSelection
          enablePinning
          manualFiltering
          manualPagination
          manualSorting
          filterFns={{
            after: (row: any, filterValue: any) =>
              row.customField === filterValue,
          }}
          muiFilterTextFieldProps={({ column }: any) => ({
            helperText: `Filter Mode: ${columnFilterFns[column?.id]}`,
          })}
          onColumnFiltersChange={setColumnFilters}
          onColumnFilterFnsChange={setColumnFilterFns}
          onGlobalFilterChange={setGlobalFilter}
          onPaginationChange={setPagination}
          onSortingChange={setSorting}
          renderToolbarInternalActions={({ table }: any) => (
            <>
              <Tooltip arrow title="Refresh Data">
                <IconButton onClick={() => refetch()}>
                  <Refresh />
                </IconButton>
              </Tooltip>
              <MRT_ToggleGlobalFilterButton table={table} />
              <MRT_ToggleFiltersButton table={table} />
              <MRT_ShowHideColumnsButton table={table} />
              <MRT_ToggleDensePaddingButton table={table} />
              <MRT_ToggleFullScreenButton table={table} />
            </>
          )}
          muiTableContainerProps={{
            sx: { maxHeight: `calc(100vh - 225px)` },
          }}
          muiTableHeadCellProps={{
            sx: {
              "& .Mui-TableHeadCell-Content": {
                justifyContent: "space-between",
              },
            },
          }}
          muiTableBodyCellProps={({ table, column }: any) => {
            return {
              sx: {
                "&.MuiTableCell-root": {
                  boxShadow:
                    table.getState().columnPinning?.right?.[0] === column?.id
                      ? "-7px 0px 10px -1.7px lightgray"
                      : table
                            .getState()
                            .columnPinning?.left?.some(
                              (el: string) => el === column.id
                            )
                        ? "7px 0px 10px -1.7px lightgray"
                        : "none",
                },
              },
            };
          }}
          rowCount={configs?.meta_data?.total ?? 0}
          state={{
            columnFilters: columnFilters || [],
            columnFilterFns,
            globalFilter: globalFilter || "",
            isLoading,
            pagination,
            sorting: sorting || [],
            showSkeletons: isLoading,
          }}
        />
      </Box>
    </Box>
  );
}
export default Configs;
