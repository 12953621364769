import { SnackbarProvider } from "notistack";
import { Box, Hidden, Slide } from "@mui/material";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";

import { ADMIN_BASE_URL, SSO_BASE_URL } from "./utils/config";
import { AuthProvider } from "./context/authProvider";
import Listener from "./component/listener";
import PersistLogin from "./component/persistLogin";
import PrivateRoute from "./component/privateRoute";
import IPIframe from "./component/ipIframe";
import Authorize from "./component/authorize";
import Main from "./container/main";

import SignIn from "./pages/signIn";
import Merchants from "./pages/merchants/merchants";
import Roles from "./pages/roles/roles";
import Users from "./pages/users/users";
import UserDetails from "./pages/users/userDetails";
import Configs from "./pages/configs/configs";
import Customers from "./pages/merchants/customers";
import OneTimePayments from "./pages/merchants/oneTimePayments";
import UserSubscriptions from "./pages/merchants/userSubscriptions";
import Products from "./pages/merchants/products";
import ProductDetails from "./pages/merchants/productDetails";
import Discounts from "./pages/merchants/discounts";
import DiscountsByCode from "./pages/merchants/discountsByCode";
import MerchantDetails from "./pages/merchants/merchantDetails";
import Transactions from "./pages/merchants/transactions";

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false,
      retry: false,
    },
  },
});

function App() {
  return (
    <Box>
      {/* Single Sign out Iframe */}
      {window.parent === window && (
        <iframe
          id="ipIframe"
          src={`${ADMIN_BASE_URL}/IPIFrame`}
          title="ipIframe"
          style={{ display: "none" }}
        />
      )}
      {window.parent === window && (
        <iframe
          id="opIframe"
          src={`${SSO_BASE_URL}/opIframe`}
          title="opIframe"
          style={{ display: "none" }}
        />
      )}
      <QueryClientProvider client={queryClient}>
        <SnackbarProvider
          anchorOrigin={{
            vertical: "bottom",
            horizontal: "left",
          }}
          TransitionComponent={Slide}
          autoHideDuration={3000}
          preventDuplicate
        >
          <BrowserRouter>
            <AuthProvider>
              {window.parent === window && (
                <Hidden>
                  <Listener />
                </Hidden>
              )}
              <Routes>
                <Route path="login" element={<SignIn />} />
                <Route path="authorize" element={<Authorize />} />
                <Route path="IPIFrame" element={<IPIframe />} />
                <Route element={<PersistLogin />}>
                  <Route
                    path="/"
                    element={
                      <PrivateRoute>
                        <Main />
                      </PrivateRoute>
                    }
                  >
                    <Route path="merchants" element={<Merchants />} />
                    <Route
                      path="merchants/:merchantId"
                      element={<Merchants />}
                    />
                    <Route
                      path="merchants/:merchantId/view"
                      element={<MerchantDetails />}
                    />
                    <Route
                      path="merchants/:merchantId/products"
                      element={<Products />}
                    />
                    <Route
                      path="merchants/:merchantId/products/:productId/view"
                      element={<ProductDetails />}
                    />
                    <Route
                      path="merchants/:merchantId/subscriptions"
                      element={<UserSubscriptions />}
                    />
                    <Route
                      path="merchants/:merchantId/one-time-payments"
                      element={<OneTimePayments />}
                    />
                    <Route
                      path="merchants/:merchantId/customers"
                      element={<Customers />}
                    />
                    <Route
                      path="merchants/:merchantId/discount-codes"
                      element={<Discounts />}
                    />
                    <Route
                      path="merchants/:merchantId/discount-codes/:discountCode/discounts"
                      element={<DiscountsByCode />}
                    />
                    <Route
                      path="merchants/:merchantId/transactions"
                      element={<Transactions />}
                    />
                    <Route path="roles" element={<Roles />} />
                    <Route path="users" element={<Users />} />
                    <Route
                      path="/users/:userPhone/details"
                      element={<UserDetails />}
                    />
                    <Route path="configs" element={<Configs />} />
                  </Route>
                </Route>
              </Routes>
            </AuthProvider>
          </BrowserRouter>
        </SnackbarProvider>
      </QueryClientProvider>
    </Box>
  );
}

export default App;
