import { useContext, useEffect, useState } from "react";
import {
  AppBar as MuiAppBar,
  Toolbar,
  IconButton,
  Typography,
  Avatar,
  Menu,
  Divider,
  Drawer as MuiDrawer,
  List,
  Button,
  ListItemButton,
  ListItemText,
  ListItemIcon,
  Collapse,
  Theme,
  styled,
  Box,
  CSSObject,
  AppBarProps as MuiAppBarProps,
  Tooltip,
  CircularProgress,
} from "@mui/material";
import MenuIcon from "@mui/icons-material/Menu";
import {
  AccountBalance,
  Dashboard,
  ExpandLess,
  ExpandMore,
  Logout,
  ManageAccounts,
  MenuOpen,
  Person2TwoTone,
  RoomPreferences,
  Settings,
} from "@mui/icons-material";
import { jwtDecode } from "jwt-decode";
import { Outlet, useLocation, useNavigate } from "react-router-dom";

import AuthContext from "../context/authProvider";
import useFetchEntities from "../hooks/useFetchEntities";
import { SSO_API_BASE_URL, SSO_ASSETS_URL, SSO_VERSION } from "../utils/config";

import Link from "../component/link";
import Page from "../component/breadCrumbs";
import ErrorPage from "../component/errorPage";
import Unauthorized from "../component/unauthorized";
import useAccessControl from "../hooks/useAccessControl";

const drawerWidth = 240;

const openedMixin = (theme: Theme): CSSObject => ({
  width: drawerWidth,
  transition: theme.transitions.create("width", {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.enteringScreen,
  }),
  overflowX: "hidden",
});

const closedMixin = (theme: Theme): CSSObject => ({
  transition: theme.transitions.create("width", {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  overflowX: "hidden",
  width: `calc(${theme.spacing(7)} + 2px)`,
  [theme.breakpoints.up("sm")]: {
    width: `calc(${theme.spacing(8)} + 3px)`,
  },
});

const DrawerHeader = styled("div")(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  justifyContent: "flex-end",
  ...theme.mixins.toolbar,
}));

interface AppBarProps extends MuiAppBarProps {
  open?: boolean;
}

const AppBar = styled(MuiAppBar, {
  shouldForwardProp: (prop) => prop !== "open",
})<AppBarProps>(({ theme, open }) => ({
  transition: theme.transitions.create(["width", "margin"], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  ...(open && {
    marginLeft: drawerWidth,
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(["width", "margin"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  }),
}));

const Drawer = styled(MuiDrawer, {
  shouldForwardProp: (prop) => prop !== "open",
})(({ theme, open }) => ({
  width: drawerWidth,
  flexShrink: 0,
  whiteSpace: "nowrap",
  boxSizing: "border-box",
  ...(open && {
    ...openedMixin(theme),
    "& .MuiDrawer-paper": openedMixin(theme),
  }),
  ...(!open && {
    ...closedMixin(theme),
    "& .MuiDrawer-paper": closedMixin(theme),
  }),
}));

export default function Main() {
  const { auth, setPermissions } = useContext(AuthContext) as any;
  const { pathname } = useLocation();
  const navigate = useNavigate();
  const [open, setOpen] = useState(true);
  const [openMangementSetting, setOpenMangementSetting] = useState(true);

  const [anchorEl, setAnchorEl] = useState(null);
  const openMenu = Boolean(anchorEl);

  const decoded = jwtDecode(auth?.id_token) as any;
  const { rolesList, systemUsersList, configsList } = useAccessControl();
  const {
    data: permissions,
    isLoading,
    error,
  } = useFetchEntities({ endPoint: "/system/users/permissions" }) as any;

  const handleClose = () => {
    setAnchorEl(null);
  };
  const handleManagementSettingClick = () => {
    setOpenMangementSetting(!openMangementSetting);
  };
  const handleClick = (event: any) => {
    setAnchorEl(event.currentTarget);
  };
  const handleSignOut = () => {
    navigate("/");
    window.localStorage.removeItem("merchantId");
    window.location.reload();
  };

  useEffect(() => {
    const permissionData = permissions?.data?.map(
      (item: { name: string }) => item.name
    );
    if (permissions?.data && permissions?.data?.length !== 0) {
      setPermissions(permissionData);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [permissions?.data]);

  if (isLoading) {
    return (
      <Box
        sx={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          mt: 2,
          minHeight: "450px",
        }}
      >
        <CircularProgress
          size={40}
          sx={{
            color: "black",
            marginTop: "-12px",
            marginLeft: "-12px",
          }}
        />
      </Box>
    );
  }

  if (error) {
    return <ErrorPage error={error} />;
  }

  if (
    permissions?.data?.data === null ||
    permissions?.data?.data?.length === 0
  ) {
    return <Unauthorized />;
  }
  return (
    <Box sx={{ display: "flex" }}>
      <AppBar
        open={open}
        sx={{
          boxShadow: "0px 2px 5px #00000029",
          background: " #F8F8F8 0% 0% no-repeat padding-box",
          py: 0.7,
        }}
      >
        <Toolbar>
          <IconButton
            color="inherit"
            aria-label="open drawer"
            onClick={() => setOpen(!open)}
            edge="start"
            sx={{
              marginRight: "36px",
              ...(open && { display: "none" }),
            }}
          >
            {open ? null : <MenuIcon sx={{ color: "black" }} />}
          </IconButton>
          <Page pathname={pathname} />
          <Box flex={1} />
          <IconButton
            size="large"
            aria-label="display more actions"
            edge="end"
            color="inherit"
            onClick={handleClick}
          >
            <Avatar
              sx={{
                height: "40px",
                width: "40px",
              }}
              variant="circular"
              src={`${SSO_API_BASE_URL}/${SSO_VERSION}/${SSO_ASSETS_URL}/${decoded?.picture}`}
            />
          </IconButton>
          <Menu
            anchorEl={anchorEl}
            open={openMenu}
            onClose={handleClose}
            onClick={handleClose}
            slotProps={{
              paper: {
                elevation: 0,
                sx: {
                  overflow: "visible",
                  filter: "drop-shadow(0px 2px 8px rgba(0,0,0,0.32))",
                  mt: 1.5,
                  "&:before": {
                    content: '""',
                    display: "block",
                    position: "absolute",
                    top: 0,
                    right: 14,
                    width: 10,
                    height: 10,
                    bgcolor: "background.paper",
                    transform: "translateY(-50%) rotate(45deg)",
                    zIndex: 0,
                  },
                },
              },
            }}
            transformOrigin={{
              horizontal: "right",
              vertical: "top",
            }}
            anchorOrigin={{
              horizontal: "right",
              vertical: "bottom",
            }}
          >
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                mt: 2,
                mb: 3,
                px: 2,
                width: 220,
              }}
            >
              <Avatar
                sx={{
                  width: 110,
                  height: 110,
                  mb: 2,
                }}
                variant="circular"
                src={`${SSO_API_BASE_URL}/${SSO_VERSION}/${SSO_ASSETS_URL}/${decoded?.picture}`}
              />
              <Typography
                sx={{
                  fontWeight: "bold",
                  maxWidth: 300,
                }}
                noWrap
              >
                {`${decoded?.first_name} ${decoded?.middle_name}`}
              </Typography>
              <Typography
                variant="caption"
                sx={{
                  color: "gray",
                  maxWidth: 300,
                }}
                noWrap
              >
                {decoded?.email}
              </Typography>
            </Box>
            <Divider />
            <Box
              sx={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                mt: 2,
                mb: 1,
                px: 2,
              }}
            >
              <Button
                fullWidth
                variant="contained"
                sx={{
                  bgcolor: "#f5f5f5",
                  color: "primary.main",
                  ":hover": { bgcolor: "#f5f5f5" },
                  mx: 3,
                  textTransform: "none",
                }}
                size="small"
                onClick={handleSignOut}
                startIcon={<Logout fontSize="small" />}
              >
                Sign Out
              </Button>
            </Box>
          </Menu>
        </Toolbar>
      </AppBar>
      <Drawer variant="permanent" open={open}>
        <Box
          sx={{
            flex: 1,
            background: "#FFFFFF 10% 0% no-repeat padding-box",
            boxShadow: "10px 3px 10px #00000029",
          }}
        >
          <Box
            sx={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              width: "100%",
              height: "76px",
              background: "#FAFAFA 0% 0% no-repeat padding-box ",
              boxShadow: "0px 3px 10px #00000029",
            }}
          >
            <Tooltip title="Inverse">
              <Typography
                noWrap
                component="div"
                sx={{
                  ml: 1.3,
                  fontSize: "23px",
                  fontWeight: "bolder",
                }}
              >
                {open ? "Inverse" : ""}
              </Typography>
            </Tooltip>
            <IconButton
              color="inherit"
              aria-label="open drawer"
              onClick={() => setOpen(!open)}
              edge="start"
              size="large"
              sx={{
                marginRight: "6px",
              }}
            >
              {open ? <MenuOpen /> : <MenuIcon />}
            </IconButton>
          </Box>
          <Avatar
            variant="square"
            sx={{
              height: "auto",
              width: open ? 168 : 86,
              mx: "auto",
              my: open ? -2 : -1,
              transition: (theme) =>
                theme.transitions.create(["width", "height", "margin"], {
                  easing: theme.transitions.easing.sharp,
                  duration: theme.transitions.duration.enteringScreen,
                }),
            }}
            src={`${process.env.PUBLIC_URL}/assets/logo.png`}
          />
          <Divider
            sx={{
              width: "100%",
              height: "12px",
              color: "#DBDBDB",
            }}
          />
          <List>
            <Link to="" icon={<Dashboard />} label="Dashoard" open={open} />
            <Link
              to="merchants"
              icon={<AccountBalance />}
              label="Merchants"
              open={open}
            />
            {(rolesList || systemUsersList) && (
              <ListItemButton onClick={handleManagementSettingClick}>
                <ListItemIcon sx={{ color: "inherit" }}>
                  <Settings />
                </ListItemIcon>
                <ListItemText sx={{ color: "inherit" }} primary="Management" />
                {openMangementSetting ? <ExpandLess /> : <ExpandMore />}
              </ListItemButton>
            )}
            <Collapse in={!openMangementSetting} timeout="auto" unmountOnExit>
              {rolesList && (
                <Link
                  to="roles"
                  icon={<ManageAccounts />}
                  label="Roles"
                  open={openMangementSetting}
                  sx={{ pl: open ? 6 : 3.5 }}
                />
              )}
              {systemUsersList && (
                <Link
                  to="users"
                  icon={<Person2TwoTone />}
                  label="Users"
                  open={openMangementSetting}
                  sx={{ pl: open ? 6 : 3.5 }}
                />
              )}
            </Collapse>
            {configsList && (
              <Link
                to="configs"
                icon={<RoomPreferences />}
                label="Configs"
                open={open}
              />
            )}
          </List>
        </Box>
      </Drawer>
      <Box
        component="main"
        sx={{
          flexGrow: 1,
          p: 3,
          // backgroundColor: "#F8F8F8",
          height: "100%",
          justifyContent: "center",
          maxWidth: open
            ? `calc(100% - ${drawerWidth}px)`
            : `calc(100% - 68px)`,
        }}
      >
        <DrawerHeader />
        <Outlet />
      </Box>
    </Box>
  );
}
