/* eslint-disable react/jsx-pascal-case */
import { useCallback, useMemo, useRef, useState } from "react";
import {
  Avatar,
  Box,
  Button,
  IconButton,
  Tooltip,
  Typography,
} from "@mui/material";
import { useNavigate } from "react-router-dom";
import { DateTime } from "luxon";
import {
  MaterialReactTable,
  MRT_ColumnDef,
  MRT_ToggleFullScreenButton,
  MRT_ShowHideColumnsButton,
  MRT_ToggleDensePaddingButton,
  MRT_ToggleFiltersButton,
  MRT_ToggleGlobalFilterButton,
} from "material-react-table";
import {
  Add,
  Edit,
  HighlightOff,
  Refresh,
  TaskAlt,
  Visibility,
} from "@mui/icons-material";
import {
  ColumnFiltersState,
  Getter,
  PaginationState,
  SortingState,
} from "@tanstack/react-table";

import filterModel from "../../hooks/filterModel";
import useFetchEntitiesPerPage from "../../hooks/useFetchEntitiesPerPage";

import CustomDateFilter from "../../component/customDateFilter";
import CustomFilterPanel from "../../component/customFilterPanel";
import StatusButton from "../../component/statusButton";

import {
  SSO_API_BASE_URL,
  SSO_ASSETS_URL,
  SSO_VERSION,
} from "../../utils/config";
import useAccessControl from "../../hooks/useAccessControl";
import AssignOrRevokeUserRole from "./assignOrRevokeRole";
import AddUsers from "./addUsers";

// System Users List View Component
function Users() {
  const { systemUsersList, assignRole, revokeRole, getUserByPhone } =
    useAccessControl();
  const navigate = useNavigate();
  // USESTATES & USEREFS
  const [columnFilters, setColumnFilters] =
    useState<ColumnFiltersState>() as any;
  const [columnFilterFns, setColumnFilterFns] = useState({
    created_at: "is",
    email: "contains",
    first_name: "contains",
    middle_name: "contains",
    last_name: "contains",
    phone: "contains",
  }) as any;
  const [globalFilter, setGlobalFilter] = useState<any>();
  const [sorting, setSorting] = useState<SortingState>() as any;
  const [pagination, setPagination] = useState<PaginationState>({
    pageIndex: 0,
    pageSize: 15,
  });
  const [open, setOpen] = useState(false);
  const [openEdit, setOpenEdit] = useState<boolean>(false);
  const userData = useRef({});
  const userRoles = useRef({});

  // QUERY & MUTATION
  const sort = useMemo(
    () =>
      sorting?.map(
        (item: {
          id?: string;
          field?: string;
          sort?: string;
          desc?: string;
        }) => ({
          field: item?.id || item?.field,
          sort: item?.sort === "desc" || item?.desc ? "desc" : "asc",
        })
      ),
    [sorting]
  );

  const {
    data: users,
    isLoading,
    refetch,
  } = useFetchEntitiesPerPage(
    {
      endPoint: "users",
      page: pagination.pageIndex,
      perPage: pagination.pageSize,
      filterModel: filterModel(columnFilterFns, columnFilters) as any,
      sortModel: sort,
      searchText: globalFilter,
    },
    {
      enabled: systemUsersList,
    }
  ) as any;

  // EVENT HANDLERS & HELPERS
  const handleModalClose = () => {
    setOpen(false);
  };
  const handleEditModalClose = () => {
    setOpenEdit(false);
  };
  const getAvatar = useCallback(
    ({ row }: { row: { original: { profile_picture: string } } }) => (
      <Avatar
        sx={{
          width: 50,
          height: 50,
        }}
        src={`${SSO_API_BASE_URL}/${SSO_VERSION}/${SSO_ASSETS_URL}/${row?.original?.profile_picture}`}
      />
    ),
    []
  );
  const statusButton = useCallback(
    ({ row }: { row: { original: { status: string } } }) => (
      <StatusButton status={row.original.status} />
    ),
    []
  );
  const actions = useCallback(
    ({ row }: { row: { original: any } }) => (
      <Box sx={{ flex: 1 }}>
        <Button
          key={row?.original?.sso_id}
          onClick={() => {
            navigate(`${row?.original?.phone}/details`);
          }}
          sx={{
            minWidth: 24,
            p: 1,
            color: "primary.main",
          }}
        >
          <Visibility />
        </Button>
      </Box>
    ),
    [navigate]
  );
  const getRoles = useCallback(
    ({ row }: { row: { original: { roles: any } } }) => (
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
        }}
      >
        <Box>
          {row?.original?.roles?.map(
            (role: { id: string; status: string; role_name: string }) => (
              <Box
                key={role.id}
                sx={{
                  display: "inline-block",
                  justifyContent: "start",
                  alignItems: "center",
                  fontWeight: "bold",
                  overflow: "hidden",
                  m: ".05em",
                  backgroundColor:
                    role?.status === "ACTIVE" ? "success.main" : "error.main",
                  color: "white",
                  borderRadius: "20px",
                  pr: "1em",
                }}
              >
                <Box
                  sx={{
                    display: "flex",
                    alignItems: "center",
                  }}
                >
                  <IconButton
                    size="small"
                    sx={{
                      color: "white",
                    }}
                  >
                    {role?.status === "ACTIVE" ? (
                      <TaskAlt sx={{ fontSize: "1em" }} />
                    ) : (
                      <HighlightOff sx={{ fontSize: "1em" }} />
                    )}
                  </IconButton>
                  <Typography variant="subtitle2" noWrap>
                    {role?.role_name}
                  </Typography>
                </Box>
              </Box>
            )
          )}
        </Box>
        <Box
          sx={{
            justifyContent: "flex-end",
          }}
        >
          <IconButton
            disabled={!assignRole || !revokeRole}
            onClick={() => {
              setOpenEdit(true);
              userData.current = row?.original;
              userRoles.current = row?.original?.roles?.map(
                (role: { role_name: string; id: string; status: string }) => ({
                  name: role?.role_name,
                  id: role?.id,
                  status: role?.status,
                })
              );
            }}
            sx={{
              fontSize: 16,
              fontWeight: "bold",
              borderRadius: 0,
              color: "primary.main",
            }}
          >
            <Edit />
          </IconButton>
        </Box>
      </Box>
    ),
    [assignRole, revokeRole]
  );

  const datePicker = (props: any) => <CustomDateFilter {...props} />;

  // DATA STRUCTURE
  const columns = useMemo<MRT_ColumnDef<object>[]>(
    () => [
      {
        size: 150,
        accessorKey: "photo",
        header: "Photo",
        enableSorting: false,
        enableColumnFilter: false,
        enableGlobalFilter: false,
        Cell: getAvatar as any,
      },
      {
        accessorKey: "first_name",
        header: "First Name",
        renderColumnFilterModeMenuItems: CustomFilterPanel,
      },
      {
        size: 220,
        accessorKey: "middle_name",
        header: "Middle Name",
        renderColumnFilterModeMenuItems: CustomFilterPanel,
      },
      {
        size: 220,
        accessorKey: "last_name",
        header: "Last Name",
        renderColumnFilterModeMenuItems: CustomFilterPanel,
      },
      {
        accessorKey: "phone",
        header: "Phone",
        renderColumnFilterModeMenuItems: CustomFilterPanel,
      },
      {
        size: 220,
        accessorKey: "email",
        header: "Email",
        renderColumnFilterModeMenuItems: CustomFilterPanel,
      },
      {
        size: 230,
        align: "center",
        accessorKey: "status",
        header: "Status",
        filterVariant: "select" as any,
        filterSelectOptions: ["ACTIVE", "INACTIVE"],
        enableSorting: false,
        enableColumnFilter: false,
        enableGlobalFilter: false,
        Cell: statusButton as any,
        renderColumnFilterModeMenuItems: CustomFilterPanel,
      },
      {
        size: 300,
        accessorKey: "role",
        header: "Role(s)",
        enableSorting: false,
        enableColumnFilter: false,
        enableGlobalFilter: false,
        Cell: getRoles as any,
        renderColumnFilterModeMenuItems: CustomFilterPanel,
      },
      {
        size: 240,
        accessorKey: "created_at",
        header: "Registered Time",
        filterVariant: "date" as any,
        renderColumnFilterModeMenuItems: CustomFilterPanel,
        Cell: ({ cell }: { cell: { getValue: Getter<string> } }) =>
          DateTime.fromISO(cell?.getValue()).toLocaleString(
            DateTime.DATETIME_MED
          ),
        Filter: datePicker,
      },
      {
        accessorKey: "actions",
        header: "Actions",
        enableSorting: false,
        enableColumnFilter: false,
        enableGlobalFilter: false,
        Cell: actions,
      },
    ],
    [actions, getAvatar, getRoles, statusButton]
  );

  return (
    <Box sx={{ height: "100%" }}>
      {open && (
        <AddUsers
          open={open}
          handleModalClose={handleModalClose}
          refetch={refetch}
        />
      )}
      {openEdit && (
        <AssignOrRevokeUserRole
          open={openEdit}
          userData={userData.current}
          handleEditModalClose={handleEditModalClose}
          refetch={refetch}
        />
      )}
      <Box sx={{ flex: 1, height: "100%" }}>
        <MaterialReactTable
          data={users ? users.data : []}
          columns={columns}
          initialState={{
            columnPinning: {
              left: ["mrt-row-select"],
              right: ["actions"],
            },
          }}
          enableColumnResizing
          enableColumnFilterModes
          enableStickyHeader
          enableColumnOrdering
          enableRowSelection
          enablePinning
          manualFiltering
          manualPagination
          manualSorting
          filterFns={{
            after: (row: any, filterValue) => row.customField === filterValue,
          }}
          muiFilterTextFieldProps={({ column }: any) => ({
            helperText: `Filter Mode: ${columnFilterFns[column?.id]}`,
          })}
          onColumnFiltersChange={setColumnFilters}
          onColumnFilterFnsChange={setColumnFilterFns}
          onGlobalFilterChange={setGlobalFilter}
          onPaginationChange={setPagination}
          onSortingChange={setSorting}
          renderTopToolbarCustomActions={() => (
            <Box
              sx={{
                display: "flex",
              }}
            >
              {getUserByPhone && (
                <Button
                  size="small"
                  sx={{
                    bgcolor: "primary.main",
                    color: "secondary.main",
                    textTransform: "none",
                    px: 2,
                    py: 1,
                    mx: 1,
                    boxShadow: 2,
                    " :hover": {
                      bgcolor: "primary.main",
                      color: "secondary.main",
                    },
                  }}
                  onClick={() => setOpen(true)}
                >
                  <Add sx={{ mr: 1 }} />
                  Add User
                </Button>
              )}
            </Box>
          )}
          renderToolbarInternalActions={({ table }: any) => (
            <>
              <Tooltip arrow title="Refresh Data">
                <IconButton onClick={() => refetch()}>
                  <Refresh />
                </IconButton>
              </Tooltip>
              <MRT_ToggleGlobalFilterButton table={table} />
              <MRT_ToggleFiltersButton table={table} />
              <MRT_ShowHideColumnsButton table={table} />
              <MRT_ToggleDensePaddingButton table={table} />
              <MRT_ToggleFullScreenButton table={table} />
            </>
          )}
          muiTableContainerProps={{
            sx: { maxHeight: `calc(100vh - 225px)` },
          }}
          muiTableHeadCellProps={{
            sx: {
              "& .Mui-TableHeadCell-Content": {
                justifyContent: "space-between",
              },
            },
          }}
          muiTableBodyCellProps={({ table, column }) => {
            return {
              sx: {
                "&.MuiTableCell-root": {
                  boxShadow:
                    table.getState().columnPinning?.right?.[0] === column?.id
                      ? "-7px 0px 10px -1.7px lightgray"
                      : table
                            .getState()
                            .columnPinning?.left?.some((el) => el === column.id)
                        ? "7px 0px 10px -1.7px lightgray"
                        : "none",
                },
              },
            };
          }}
          rowCount={users?.meta_data?.total ?? 0}
          state={{
            columnFilters: columnFilters || [],
            columnFilterFns,
            globalFilter: globalFilter || "",
            isLoading,
            pagination,
            sorting: sorting || [],
            showSkeletons: isLoading,
          }}
        />
      </Box>
    </Box>
  );
}
export default Users;
