/* eslint-disable react/jsx-pascal-case */
import { useMemo, useCallback, useState } from "react";
import { Link } from "react-router-dom";
import { Box, Chip, IconButton, Tooltip } from "@mui/material";
import { Refresh, Visibility } from "@mui/icons-material";
import {
  MRT_ColumnDef,
  MRT_ToggleFullScreenButton,
  MRT_ShowHideColumnsButton,
  MRT_ToggleDensePaddingButton,
  MRT_ToggleFiltersButton,
  MRT_ToggleGlobalFilterButton,
  MaterialReactTable,
} from "material-react-table";
import {
  ColumnFiltersState,
  Getter,
  PaginationState,
  SortingState,
} from "@tanstack/react-table";
import { DateTime } from "luxon";

import useFetchEntitiesPerPage from "../../hooks/useFetchEntitiesPerPage";
import useAccessControl from "../../hooks/useAccessControl";
import filterModel from "../../hooks/filterModel";

import CustomFilterPanel from "../../component/customFilterPanel";
import CustomDateFilter from "../../component/customDateFilter";
import StatusButton from "../../component/statusButton";

function Products() {
  const { productsList, productDetails } = useAccessControl();
  const [columnFilters, setColumnFilters] =
    useState<ColumnFiltersState>() as any;
  const [columnFilterFns, setColumnFilterFns] = useState({
    created_at: "is",
    name: "contains",
    prices: "contains",
    description: "contains",
    status: "is",
  }) as any;
  const [globalFilter, setGlobalFilter] = useState<any>();
  const [sorting, setSorting] = useState<SortingState>() as any;
  const [pagination, setPagination] = useState<PaginationState>({
    pageIndex: 0,
    pageSize: 15,
  });

  const sort = useMemo(
    () =>
      sorting?.map(
        (item: {
          id?: string;
          field?: string;
          sort?: string;
          desc?: string;
        }) => ({
          field: item?.id || item?.field,
          sort: item?.sort === "desc" || item?.desc ? "desc" : "asc",
        })
      ),
    [sorting]
  );

  const {
    data: products,
    isLoading,
    refetch,
  } = useFetchEntitiesPerPage(
    {
      endPoint: "products",
      page: pagination.pageIndex,
      perPage: pagination.pageSize,
      filterModel: filterModel(columnFilterFns, columnFilters) as any,
      sortModel: sort,
      searchText: globalFilter,
    },
    {
      enabled: productsList,
    }
  ) as any;

  // Event Handlers & Helpers
  const statusButton = useCallback(
    ({ row }: { row: { original: any } }) => (
      <StatusButton status={row.original.status} />
    ),
    []
  );

  const actions = useCallback(
    ({ row }: { row: { original: any } }) => (
      <Box>
        <Tooltip title="View product" hidden={!productDetails}>
          <Link to={`${row.original.id}/view`} state={row.original}>
            <IconButton sx={{ color: "primary.main" }}>
              <Visibility />
            </IconButton>
          </Link>
        </Tooltip>
      </Box>
    ),
    [productDetails]
  );
  const prices = useCallback(
    ({ row }: { row: { original: any } }) => (
      <Box
        sx={{
          display: "inline-block",
          justifyContent: "start",
          alignItems: "flex-start",
          fontWeight: "bold",
        }}
      >
        {row?.original?.prices.length === 0
          ? "-"
          : row?.original?.prices?.map((price: any) =>
              price.name ? (
                <Chip
                  key={price?.id}
                  label={price?.name}
                  sx={{
                    my: 0.5,
                    mr: 0.5,
                    color: "primary.main",
                  }}
                />
              ) : (
                "-"
              )
            )}
      </Box>
    ),
    []
  );
  const dateFilter = useCallback(
    (dateProps: any) => <CustomDateFilter {...dateProps} />,
    []
  );

  // DATA STRUCTURE
  const columns = useMemo<MRT_ColumnDef<object>[]>(
    () => [
      {
        size: 300,
        accessorKey: "created_at",
        header: "Registered Time",
        filterVariant: "date" as any,
        align: "center",
        renderColumnFilterModeMenuItems: CustomFilterPanel,
        Cell: ({ cell }: { cell: { getValue: Getter<string> } }) =>
          DateTime.fromISO(cell?.getValue()).toLocaleString(
            DateTime.DATETIME_MED
          ),
        Filter: dateFilter as any,
      },
      {
        size: 240,
        accessorKey: "name",
        header: "Name",
        align: "center",
        renderColumnFilterModeMenuItems: CustomFilterPanel,
      },
      {
        size: 300,
        accessorKey: "prices",
        header: "Subscription Plan(s)",
        align: "center",
        Cell: prices,
        renderColumnFilterModeMenuItems: CustomFilterPanel,
      },
      {
        size: 300,
        accessorKey: "description",
        header: "Description",
        align: "center",
        renderColumnFilterModeMenuItems: CustomFilterPanel,
      },
      {
        size: 240,
        accessorKey: "status",
        header: "Status",
        filterVariant: "select" as any,
        filterSelectOptions: ["ACTIVE", "PENDING", "INACTIVE"],
        Cell: statusButton,
        renderColumnFilterModeMenuItems: CustomFilterPanel,
        align: "center",
      },
      {
        size: 240,
        accessorKey: "actions",
        header: "Actions",
        enableSorting: false,
        enableColumnFilter: false,
        enableGlobalFilter: false,
        align: "center",
        Cell: actions,
      },
    ],
    [actions, dateFilter, prices, statusButton]
  );
  return (
    <Box sx={{ height: "100%" }}>
      <Box sx={{ flex: 1, height: "100%" }}>
        <MaterialReactTable
          data={products ? products.data : []}
          columns={columns}
          initialState={{
            columnPinning: {
              left: ["mrt-row-select"],
              right: ["actions"],
            },
          }}
          enableColumnResizing
          enableColumnFilterModes
          enableStickyHeader
          enableColumnOrdering
          enableRowSelection
          enablePinning
          manualFiltering
          manualPagination
          manualSorting
          filterFns={{
            after: (row: any, filterValue) => row.customField === filterValue,
          }}
          muiFilterTextFieldProps={({ column }) => ({
            helperText: `Filter Mode: ${columnFilterFns[column?.id]}`,
          })}
          onColumnFiltersChange={setColumnFilters}
          onColumnFilterFnsChange={setColumnFilterFns}
          onGlobalFilterChange={setGlobalFilter}
          onPaginationChange={setPagination}
          onSortingChange={setSorting}
          renderToolbarInternalActions={({ table }) => (
            <>
              <Tooltip arrow title="Refresh Data">
                <IconButton onClick={() => refetch()}>
                  <Refresh />
                </IconButton>
              </Tooltip>
              <MRT_ToggleGlobalFilterButton table={table} />
              <MRT_ToggleFiltersButton table={table} />
              <MRT_ShowHideColumnsButton table={table} />
              <MRT_ToggleDensePaddingButton table={table} />
              <MRT_ToggleFullScreenButton table={table} />
            </>
          )}
          muiTableContainerProps={{
            sx: { maxHeight: `calc(100vh - 225px)` },
          }}
          muiTableHeadCellProps={{
            sx: {
              "& .Mui-TableHeadCell-Content": {
                justifyContent: "space-between",
              },
            },
          }}
          muiTableBodyCellProps={({ table, column }) => {
            return {
              sx: {
                "&.MuiTableCell-root": {
                  boxShadow:
                    table.getState().columnPinning?.right?.[0] === column?.id
                      ? "-7px 0px 10px -1.7px lightgray"
                      : table
                            .getState()
                            .columnPinning?.left?.some((el) => el === column.id)
                        ? "7px 0px 10px -1.7px lightgray"
                        : "none",
                },
              },
            };
          }}
          rowCount={products?.meta_data?.total ?? 0}
          state={{
            columnFilters: columnFilters || [],
            columnFilterFns,
            globalFilter: globalFilter || "",
            isLoading,
            pagination,
            sorting: sorting || [],
            showSkeletons: isLoading,
          }}
        />
      </Box>
    </Box>
  );
}

export default Products;
