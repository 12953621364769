/* eslint-disable react/jsx-pascal-case */
import { useMemo, useCallback, useState } from "react";
import { Box, IconButton, Tooltip } from "@mui/material";
import {
  MaterialReactTable,
  MRT_ColumnDef,
  MRT_ShowHideColumnsButton,
  MRT_ToggleDensePaddingButton,
  MRT_ToggleFiltersButton,
  MRT_ToggleGlobalFilterButton,
  MRT_ToggleFullScreenButton,
} from "material-react-table";
import { DateTime } from "luxon";
import {
  ColumnFiltersState,
  Getter,
  PaginationState,
  SortingState,
} from "@tanstack/react-table";
import { useParams } from "react-router-dom";
import { Refresh } from "@mui/icons-material";

import filterModel from "../../hooks/filterModel";
import PaymentStatus from "../../component/paymentStatus";
import useFetchEntities from "../../hooks/useFetchEntities";
import useAccessControl from "../../hooks/useAccessControl";
import CustomDateFilter from "../../component/customDateFilter";
import CustomFilterPanel from "../../component/customFilterPanel";
import useFetchEntitiesPerPage from "../../hooks/useFetchEntitiesPerPage";

function OneTimePayments() {
  const { merchantId } = useParams();
  const { paymentsList } = useAccessControl();
  const [columnFilters, setColumnFilters] =
    useState<ColumnFiltersState>() as any;
  const [columnFilterFns, setColumnFilterFns] = useState({
    created_at: "is",
    customer_name: "contains",
    customer_phone_number: "contains",
    customer_email: "contains",
    amount: "=",
    payment_type: "is",
    call_back_url: "contains",
    return_url: "contains",
    status: "is",
    updated_at: "is",
  }) as any;
  const [globalFilter, setGlobalFilter] = useState<any>();
  const [sorting, setSorting] = useState<SortingState>() as any;
  const [pagination, setPagination] = useState<PaginationState>({
    pageIndex: 0,
    pageSize: 15,
  });

  const sort = useMemo(
    () =>
      sorting?.map(
        (item: {
          id?: string;
          field?: string;
          sort?: string;
          desc?: string;
        }) => ({
          field: item?.id || item?.field,
          sort: item?.sort === "desc" || item?.desc ? "desc" : "asc",
        })
      ),
    [sorting]
  );

  const {
    data: webPayments,
    isLoading,
    refetch,
  } = useFetchEntitiesPerPage(
    {
      endPoint: `merchants/${merchantId}/payment-intents`,
      page: pagination.pageIndex,
      perPage: pagination.pageSize,
      filterModel: filterModel(columnFilterFns, columnFilters) as any,
      sortModel: sort,
      searchText: globalFilter,
    },
    {
      enabled: paymentsList,
    }
  ) as any;
  const { isLoading: currenciesLoading, data: currencies } = useFetchEntities({
    endPoint: "/currencies",
  }) as any;

  // Event Handlers & Helpers
  const statusButton = useCallback(
    ({ row }: { row: { original: any } }) => (
      <PaymentStatus status={row?.original?.status} variant="standard" />
    ),
    []
  );
  const currency = useCallback(
    ({ row }: { row: { original: { currency_id: string } } }) =>
      currencies?.data
        ?.filter((item: any) => item?.id === row?.original?.currency_id)
        ?.map((el: any) => el?.code),
    [currencies?.data]
  );

  const dateFilter = useCallback(
    (dateProps: any) => <CustomDateFilter {...dateProps} />,
    []
  );
  // DATA STRUCTURE
  const columns = useMemo<MRT_ColumnDef<object>[]>(
    () => [
      {
        size: 300,
        accessorKey: "created_at",
        header: "Registered Time",
        filterVariant: "date" as any,
        renderColumnFilterModeMenuItems: CustomFilterPanel,
        Cell: ({ cell }: { cell: { getValue: Getter<string> } }) =>
          DateTime.fromISO(cell?.getValue()).toLocaleString(
            DateTime.DATETIME_MED
          ),
        Filter: dateFilter as any,
      },
      {
        size: 240,
        accessorKey: "customer_name",
        header: "Customer Name",
        Cell: ({ row }: { row: { original: any } }) =>
          row.original.customer.full_name,
        renderColumnFilterModeMenuItems: CustomFilterPanel,
      },
      {
        size: 240,
        accessorKey: "customer_phone_number",
        header: "Customer Phone",
        Cell: ({ row }: { row: { original: any } }) =>
          row.original.customer.phone_number,
        renderColumnFilterModeMenuItems: CustomFilterPanel,
      },
      {
        size: 240,
        accessorKey: "customer_email",
        header: "Customer Email",
        Cell: ({ row }: { row: { original: any } }) =>
          row.original.customer.email,
        renderColumnFilterModeMenuItems: CustomFilterPanel,
      },
      {
        size: 240,
        accessorKey: "amount",
        header: "Amount",
        filterVariant: "number" as any,
        renderColumnFilterModeMenuItems: CustomFilterPanel,
      },
      {
        size: 240,
        accessorKey: "currency_id",
        header: "Currency",
        enableSorting: false,
        enableColumnFilter: false,
        enableGlobalFilter: false,
        Cell: currency as any,
        renderColumnFilterModeMenuItems: CustomFilterPanel,
      },
      {
        size: 240,
        accessorKey: "payment_type",
        header: "Type",
        filterVariant: "select" as any,
        filterSelectOptions: ["ONCE", "RECURRING", "FOREVER"],
        renderColumnFilterModeMenuItems: CustomFilterPanel,
      },
      {
        size: 240,
        accessorKey: "reason_failed",
        header: "Failure Reason",
        enableSorting: false,
        enableColumnFilter: false,
        enableGlobalFilter: false,
        renderColumnFilterModeMenuItems: CustomFilterPanel,
      },
      {
        size: 240,
        accessorKey: "status",
        header: "Status",
        filterVariant: "select" as any,
        filterSelectOptions: ["SUCCEEDED", "PENDING", "FAILED"],
        Cell: statusButton,
        renderColumnFilterModeMenuItems: CustomFilterPanel,
      },
      {
        size: 300,
        accessorKey: "updated_at",
        header: "Last Updated",
        filterVariant: "date" as any,
        renderColumnFilterModeMenuItems: CustomFilterPanel,
        Cell: ({ cell }: { cell: { getValue: Getter<string> } }) =>
          DateTime.fromISO(cell?.getValue()).toLocaleString(
            DateTime.DATETIME_MED
          ),
        Filter: dateFilter as any,
      },
      // {
      //     size: 240,
      //     accessorKey: "actions",
      //     header: "Actions",
      //     enableSorting: false,
      //     enableColumnFilter: false,
      //     enableGlobalFilter: false,
      //     Cell: actions,
      // },
    ],
    [dateFilter, currency, statusButton]
  );
  return (
    <Box sx={{ height: "100%" }}>
      <Box sx={{ flex: 1, height: "100%" }}>
        <MaterialReactTable
          data={webPayments ? webPayments.data : []}
          columns={columns}
          initialState={{
            columnPinning: {
              left: ["mrt-row-select"],
              right: ["actions"],
            },
          }}
          enableColumnResizing
          enableColumnFilterModes
          enableStickyHeader
          enableColumnOrdering
          enableRowSelection
          enablePinning
          manualFiltering
          manualPagination
          manualSorting
          filterFns={{
            after: (row: any, filterValue) => row.customField === filterValue,
          }}
          muiFilterTextFieldProps={({ column }) => ({
            helperText: `Filter Mode: ${columnFilterFns[column?.id]}`,
          })}
          onColumnFiltersChange={setColumnFilters}
          onColumnFilterFnsChange={setColumnFilterFns}
          onGlobalFilterChange={setGlobalFilter}
          onPaginationChange={setPagination}
          onSortingChange={setSorting}
          renderToolbarInternalActions={({ table }) => (
            <>
              <Tooltip arrow title="Refresh Data">
                <IconButton onClick={() => refetch()}>
                  <Refresh />
                </IconButton>
              </Tooltip>
              <MRT_ToggleGlobalFilterButton table={table} />
              <MRT_ToggleFiltersButton table={table} />
              <MRT_ShowHideColumnsButton table={table} />
              <MRT_ToggleDensePaddingButton table={table} />
              <MRT_ToggleFullScreenButton table={table} />
            </>
          )}
          muiTableContainerProps={{
            sx: { maxHeight: `calc(100vh - 225px)` },
          }}
          muiTableHeadCellProps={{
            sx: {
              "& .Mui-TableHeadCell-Content": {
                justifyContent: "space-between",
              },
            },
          }}
          muiTableBodyCellProps={({ table, column }) => {
            return {
              sx: {
                "&.MuiTableCell-root": {
                  boxShadow:
                    table.getState().columnPinning?.right?.[0] === column?.id
                      ? "-7px 0px 10px -1.7px lightgray"
                      : table
                            .getState()
                            .columnPinning?.left?.some((el) => el === column.id)
                        ? "7px 0px 10px -1.7px lightgray"
                        : "none",
                },
              },
            };
          }}
          rowCount={webPayments?.meta_data?.total ?? 0}
          state={{
            columnFilters: columnFilters || [],
            columnFilterFns,
            globalFilter: globalFilter || "",
            isLoading,
            pagination,
            sorting: sorting || [],
            showSkeletons: isLoading || currenciesLoading,
          }}
        />
      </Box>
    </Box>
  );
}

export default OneTimePayments;
