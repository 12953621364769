import {
  Avatar,
  Box,
  CircularProgress,
  Grid,
  TextField,
  Typography,
} from "@mui/material";
import { useParams } from "react-router-dom";
import useFetchEntities from "../../hooks/useFetchEntities";
import ErrorPage from "../../component/errorPage";

// Merchant Details View Component
function MerchantDetails() {
  const { merchantId } = useParams();

  const { data, isLoading, error } = useFetchEntities({
    endPoint: `/system/merchants/${merchantId}`,
  }) as any;

  if (isLoading) {
    return (
      <Box
        sx={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          mt: 2,
          minHeight: "450px",
        }}
      >
        <CircularProgress
          size={40}
          sx={{
            color: "black",
            marginTop: "-12px",
            marginLeft: "-12px",
          }}
        />
      </Box>
    );
  }
  if (error) {
    return <ErrorPage error={error} />;
  }
  return (
    <Grid
      container
      sx={{
        bgcolor: "#fff",
      }}
    >
      <Grid item sm={12} xs={12} sx={{ mx: 2, mt: 2 }}>
        <Typography
          sx={{
            fontWeight: "bold",
            fontSize: 20,
            ml: { xs: 0, sm: 2 },
          }}
        >
          Profile
        </Typography>
        <Grid item>
          <Box
            sx={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              flexDirection: "column",
              my: 2,
            }}
          >
            <Avatar
              variant="square"
              sx={{
                width: { sm: 100, xs: 60 },
                height: { sm: 90, xs: 50 },
              }}
              src=""
            />
          </Box>
        </Grid>
        <Grid container>
          <Grid item md={5.25} sm={12} xs={12} sx={{ mx: { xs: 0, sm: 2 } }}>
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                mb: 2,
              }}
            >
              <Typography
                sx={{
                  fontWeight: "500",
                  fontSize: 14,
                  color: "#9f9b94",
                }}
              >
                Trade Name
              </Typography>
              <TextField
                size="small"
                variant="outlined"
                defaultValue={data?.data?.name}
                disabled
                multiline
                fullWidth
                sx={{
                  fontSize: 14,
                  "& .MuiInputBase-input.Mui-disabled": {
                    WebkitTextFillColor: "black",
                    fontWeight: "bold",
                  },
                }}
              />
            </Box>
          </Grid>
          <Grid item md={5.25} sm={12} xs={12} sx={{ mx: { xs: 0, sm: 2 } }}>
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                mb: 2,
              }}
            >
              <Typography
                sx={{
                  fontWeight: "500",
                  fontSize: 14,
                  color: "#9f9b94",
                }}
              >
                Manager/Business
              </Typography>
              <TextField
                size="small"
                variant="outlined"
                defaultValue={data?.data?.merchant_name}
                disabled
                multiline
                sx={{
                  fontSize: 14,
                  "& .MuiInputBase-input.Mui-disabled": {
                    WebkitTextFillColor: "black",
                    fontWeight: "bold",
                  },
                }}
              />
            </Box>
          </Grid>
          <Grid item md={5.25} sm={12} xs={12} sx={{ mx: { xs: 0, sm: 2 } }}>
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                mb: 2,
              }}
            >
              <Typography
                sx={{
                  fontWeight: "500",
                  fontSize: 14,
                  color: "#9f9b94",
                }}
              >
                Phone
              </Typography>
              <TextField
                size="small"
                variant="outlined"
                defaultValue={data?.data?.phone_number}
                disabled
                multiline
                sx={{
                  fontSize: 14,
                  "& .MuiInputBase-input.Mui-disabled": {
                    WebkitTextFillColor: "black",
                    fontWeight: "bold",
                  },
                }}
              />
            </Box>
          </Grid>
          <Grid item md={5.25} sm={12} xs={12} sx={{ mx: { xs: 0, sm: 2 } }}>
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                mb: 2,
              }}
            >
              <Typography
                sx={{
                  fontWeight: "500",
                  fontSize: 14,
                  color: "#9f9b94",
                }}
              >
                Email
              </Typography>
              <TextField
                size="small"
                variant="outlined"
                defaultValue={data?.data?.email}
                disabled
                multiline
                sx={{
                  fontSize: 14,
                  "& .MuiInputBase-input.Mui-disabled": {
                    WebkitTextFillColor: "black",
                    fontWeight: "bold",
                  },
                }}
              />
            </Box>
          </Grid>
          <Grid item md={5.25} sm={12} xs={12} sx={{ mx: { xs: 0, sm: 2 } }}>
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                mb: 2,
              }}
            >
              <Typography
                sx={{
                  fontWeight: "500",
                  fontSize: 14,
                  color: "#9f9b94",
                }}
              >
                Address
              </Typography>
              <TextField
                size="small"
                variant="outlined"
                defaultValue={data?.data?.address}
                disabled
                multiline
                sx={{
                  fontSize: 14,
                  "& .MuiInputBase-input.Mui-disabled": {
                    WebkitTextFillColor: "black",
                    fontWeight: "bold",
                  },
                }}
              />
            </Box>
          </Grid>
          <Grid item md={5.25} sm={12} xs={12} sx={{ mx: { xs: 0, sm: 2 } }}>
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                mb: 2,
              }}
            >
              <Typography
                sx={{
                  fontWeight: "500",
                  fontSize: 14,
                  color: "#9f9b94",
                }}
              >
                TIN
              </Typography>
              <TextField
                size="small"
                variant="outlined"
                defaultValue={data?.data?.tin_number}
                disabled
                multiline
                sx={{
                  fontSize: 14,
                  "& .MuiInputBase-input.Mui-disabled": {
                    WebkitTextFillColor: "black",
                    fontWeight: "bold",
                  },
                }}
              />
            </Box>
          </Grid>
          <Grid item md={5.25} sm={12} xs={12} sx={{ mx: { xs: 0, sm: 2 } }}>
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                mb: 2,
              }}
            >
              <Typography
                sx={{
                  fontWeight: "500",
                  fontSize: 14,
                  color: "#9f9b94",
                }}
              >
                Service Fee
              </Typography>
              <TextField
                size="small"
                variant="outlined"
                defaultValue={`${Number(data?.data?.service_fee).toFixed(2)}`}
                disabled
                multiline
                sx={{
                  fontSize: 14,
                  "& .MuiInputBase-input.Mui-disabled": {
                    WebkitTextFillColor: "black",
                    fontWeight: "bold",
                  },
                }}
              />
            </Box>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
}
export default MerchantDetails;
